import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { usePromo, promoStart, promoEnd } from "../../utils/usePromo";

import "./style.scss"
import NewButton from "../NewButton"
import { useWindowWidth } from "../../services/hooks"
import WebpImage from "../WebpImage"
import ProductTag from "../ProductTag";
import {getHubblePricing} from "../../services/data";

const PcpItem = ({ title, image, hoverImage, feature, product, price, originalPrice, color, packSize, optionIndex, type, isSlider, link }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()
  const itemImage = image || `Pages/ContactsPCP/Hubble-${product}-Contacts-PCP-01.png`
  const hoveredItemImage = hoverImage || image || `Pages/ContactsPCP/Hubble-${product}-Contacts-PCP-02.png`
  const isPromo = usePromo(promoStart, promoEnd) && (title.includes('Hydro') || title.includes('SkyHy'))
  const isHydro = type === 'hydro'
  const isAvg = type === 'avg'
  const linkTo = link || (isAvg || type === "avg" ?
    `/avg-product/${product.toLowerCase()}${optionIndex > 0 ? `#${packSize}` : '' }` :
    `/contact-lenses/${product.toLowerCase()}-by-hubble-lenses`)
  const isSkyhy = type === 'skyhy'
  const hubblePricing = getHubblePricing()

  useEffect(() => {
      setIsMobile(windowWidth < 578)
  }, [windowWidth])

  return (
    <div
      className={`pcp-contacts-item ${isSlider ? 'slider' : ''}`}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      data-color={color}
      data-type={type}
      onClick={() => {if(isMobile) navigate(linkTo)}}
    >
      { isPromo ?
        isHydro && <ProductTag text='80% OFF' label isPromo={isPromo} /> ||
        isSkyhy && <ProductTag text='66% OFF' label isPromo={isPromo} />
        :
        isHydro && <ProductTag text='Best Value' color='purple' label />
      }
      {(isHydro || isSkyhy) &&
        <div className="top-banner" data-type={isHydro ? "hydro" : "skyhy"}>
          {isHydro ? "POPULAR CHOICE" : "PREMIUM PICK"}
        </div>
      }
      <Link to={linkTo}>
        <div className="image-container">
        <WebpImage loading={'eager'} fileName={isHovered && !isMobile ? hoveredItemImage : itemImage} objectFit={isHovered && !isMobile ? "cover" : "contain"} />
        {!isMobile && <WebpImage loading={'eager'} fileName={hoveredItemImage} objectFit={"cover"} className="d-none" />}
        {isHovered && !isMobile && (
          <div className="button-container">
            <NewButton label="Shop Now" to={linkTo} />
          </div>
        )}
        </div>
      </Link>
      <div className={`product-info-container ${isAvg ? 'avg' : ''}`}>
        <div className="info">
          <p className="feature">{feature}</p>
          <p className="title text h5">{title === 'Hubble' ? "Hubble Contact Lenses" : title}</p>
          { packSize && <p className="pack-size text">{`${packSize} Pack`}</p>}
          <div className="price-wrapper">
            <p className="price text neue">
              <span className={`old-price text neue ${isAvg ? 'avg' : ''}`}>{originalPrice}</span>
              <span className={isPromo && 'promo-price'}>{price}</span>
            </p>
          </div>
        </div>
      </div>
      {isSlider &&
          <div className="homepage-slider-button">
            <NewButton label="Shop Now" to={linkTo} color='black'/>
          </div>
      }

      {isMobile && !isSlider &&
        <div className="cta-button">
          <NewButton label="Shop Now" to={linkTo} color='black'/>
        </div>
      }
    </div>
  )
}

export default PcpItem
